h1, h2, h3, h4, h5, h6, li, a, label, input, select, p, span, div, textarea {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

h1, h2, h3, h4, h5, h6, label, input, select, p, textarea {
    line-height: $normal-line-height; }

*:focus {
    //outline-color:
    //outline-style: dotted //IE
    outline-style: auto; }

a {
    text-decoration: none;
    font-size: 0; }

div {
    box-sizing: border-box;
    //line-height: 0
 }    //font-size: 0

html {
    font-size: calc-percentage(16px, 16px, %); }

button {
    padding: 0;
    border: none;
    border-radius: 0;
    background-color: transparent;
    cursor: pointer; }

pre {
    white-space: pre-line; }

.clearfix:after {
    clear: both;
    content: "";
    display: table; }

.table-style {
    display: table; }

.table-row-style {
    display: table-row;

    .decoration {
        display: table-cell; } }

.picture-container {
    // win7 ie11 bug fix
    width: 100%;

    .tablet, .mobile {
        display: none; } }

.img-container {
    position: relative;
    overflow: hidden;

    &.cover {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;'; }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $bg-color; }

        &.loaded {
            &:after {
                animation: img-pre-fadeIn .5s cubic-bezier(0.4, 0, 1, 1) 0s both; }

            @keyframes img-pre-fadeIn {
                0% {
                    opacity: 1; }

                100% {
                    opacity: 0; } } } }

    &.contain {
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            font-family: 'object-fit: contain;'; } } }

@media all and (max-width: $tablet) {
    .picture-container {
        .desktop {
            display: none; }

        .tablet {
            display: block; } } }

@media all and (max-width: $mobile) {
    .picture-container {
        .tablet {
            display: none; }

        .mobile {
            display: block; } } }

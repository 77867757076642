@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-family: "PingFangTC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1, h2, h3, h4, h5, h6, li, a, label, input, select, p, span, div, textarea {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, label, input, select, p, textarea {
  line-height: 1.375;
}

*:focus {
  outline-style: auto;
}

a {
  text-decoration: none;
  font-size: 0;
}

div {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

button {
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
}

pre {
  white-space: pre-line;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.table-style {
  display: table;
}

.table-row-style {
  display: table-row;
}

.table-row-style .decoration {
  display: table-cell;
}

.picture-container {
  width: 100%;
}

.picture-container .tablet, .picture-container .mobile {
  display: none;
}

.img-container {
  position: relative;
  overflow: hidden;
}

.img-container.cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.img-container.cover:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f4;
}

.img-container.cover.loaded:after {
  animation: img-pre-fadeIn 0.5s cubic-bezier(0.4, 0, 1, 1) 0s both;
}

@keyframes img-pre-fadeIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.img-container.contain img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  font-family: 'object-fit: contain;';
}

@media all and (max-width: 768px) {
  .picture-container .desktop {
    display: none;
  }
  .picture-container .tablet {
    display: block;
  }
}

@media all and (max-width: 480px) {
  .picture-container .tablet {
    display: none;
  }
  .picture-container .mobile {
    display: block;
  }
}

body {
  background-color: #f4f4f4;
}

.page-wrapper {
  min-height: calc(100vh - 293px);
}

.page-wrapper:after {
  content: '';
  display: flex;
}

.full-width-wrapper {
  width: 100%;
}

.grid-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 1232px;
}

.card-wrapper {
  margin-top: -56px;
  margin-bottom: 56px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem;
}

.grid > [class*='col'] {
  flex: 1 1;
  padding: 0 1rem;
}

.grid > .col-1 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
}

.grid > .col-1-offset-1 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 8.3333333333%;
}

.grid > .col-1-offset-2 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 16.6666666667%;
}

.grid > .col-1-offset-3 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 25%;
}

.grid > .col-1-offset-4 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 33.3333333333%;
}

.grid > .col-1-offset-5 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 41.6666666667%;
}

.grid > .col-1-offset-6 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 50%;
}

.grid > .col-1-offset-7 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 58.3333333333%;
}

.grid > .col-1-offset-8 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 66.6666666667%;
}

.grid > .col-1-offset-9 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 75%;
}

.grid > .col-1-offset-10 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 83.3333333333%;
}

.grid > .col-1-offset-11 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 91.6666666667%;
}

.grid > .col-1-offset-12 {
  flex-basis: 8.3333333333%;
  max-width: 8.3333333333%;
  margin-left: 100%;
}

.grid > .col-2 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
}

.grid > .col-2-offset-1 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 8.3333333333%;
}

.grid > .col-2-offset-2 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 16.6666666667%;
}

.grid > .col-2-offset-3 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 25%;
}

.grid > .col-2-offset-4 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 33.3333333333%;
}

.grid > .col-2-offset-5 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 41.6666666667%;
}

.grid > .col-2-offset-6 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 50%;
}

.grid > .col-2-offset-7 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 58.3333333333%;
}

.grid > .col-2-offset-8 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 66.6666666667%;
}

.grid > .col-2-offset-9 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 75%;
}

.grid > .col-2-offset-10 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 83.3333333333%;
}

.grid > .col-2-offset-11 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 91.6666666667%;
}

.grid > .col-2-offset-12 {
  flex-basis: 16.6666666667%;
  max-width: 16.6666666667%;
  margin-left: 100%;
}

.grid > .col-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.grid > .col-3-offset-1 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 8.3333333333%;
}

.grid > .col-3-offset-2 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 16.6666666667%;
}

.grid > .col-3-offset-3 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 25%;
}

.grid > .col-3-offset-4 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 33.3333333333%;
}

.grid > .col-3-offset-5 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 41.6666666667%;
}

.grid > .col-3-offset-6 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 50%;
}

.grid > .col-3-offset-7 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 58.3333333333%;
}

.grid > .col-3-offset-8 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 66.6666666667%;
}

.grid > .col-3-offset-9 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 75%;
}

.grid > .col-3-offset-10 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 83.3333333333%;
}

.grid > .col-3-offset-11 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 91.6666666667%;
}

.grid > .col-3-offset-12 {
  flex-basis: 25%;
  max-width: 25%;
  margin-left: 100%;
}

.grid > .col-4 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
}

.grid > .col-4-offset-1 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 8.3333333333%;
}

.grid > .col-4-offset-2 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 16.6666666667%;
}

.grid > .col-4-offset-3 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 25%;
}

.grid > .col-4-offset-4 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 33.3333333333%;
}

.grid > .col-4-offset-5 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 41.6666666667%;
}

.grid > .col-4-offset-6 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 50%;
}

.grid > .col-4-offset-7 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 58.3333333333%;
}

.grid > .col-4-offset-8 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 66.6666666667%;
}

.grid > .col-4-offset-9 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 75%;
}

.grid > .col-4-offset-10 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 83.3333333333%;
}

.grid > .col-4-offset-11 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 91.6666666667%;
}

.grid > .col-4-offset-12 {
  flex-basis: 33.3333333333%;
  max-width: 33.3333333333%;
  margin-left: 100%;
}

.grid > .col-5 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
}

.grid > .col-5-offset-1 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 8.3333333333%;
}

.grid > .col-5-offset-2 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 16.6666666667%;
}

.grid > .col-5-offset-3 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 25%;
}

.grid > .col-5-offset-4 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 33.3333333333%;
}

.grid > .col-5-offset-5 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 41.6666666667%;
}

.grid > .col-5-offset-6 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 50%;
}

.grid > .col-5-offset-7 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 58.3333333333%;
}

.grid > .col-5-offset-8 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 66.6666666667%;
}

.grid > .col-5-offset-9 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 75%;
}

.grid > .col-5-offset-10 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 83.3333333333%;
}

.grid > .col-5-offset-11 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 91.6666666667%;
}

.grid > .col-5-offset-12 {
  flex-basis: 41.6666666667%;
  max-width: 41.6666666667%;
  margin-left: 100%;
}

.grid > .col-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.grid > .col-6-offset-1 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 8.3333333333%;
}

.grid > .col-6-offset-2 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 16.6666666667%;
}

.grid > .col-6-offset-3 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 25%;
}

.grid > .col-6-offset-4 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 33.3333333333%;
}

.grid > .col-6-offset-5 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 41.6666666667%;
}

.grid > .col-6-offset-6 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 50%;
}

.grid > .col-6-offset-7 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 58.3333333333%;
}

.grid > .col-6-offset-8 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 66.6666666667%;
}

.grid > .col-6-offset-9 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 75%;
}

.grid > .col-6-offset-10 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 83.3333333333%;
}

.grid > .col-6-offset-11 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 91.6666666667%;
}

.grid > .col-6-offset-12 {
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 100%;
}

.grid > .col-7 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
}

.grid > .col-7-offset-1 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 8.3333333333%;
}

.grid > .col-7-offset-2 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 16.6666666667%;
}

.grid > .col-7-offset-3 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 25%;
}

.grid > .col-7-offset-4 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 33.3333333333%;
}

.grid > .col-7-offset-5 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 41.6666666667%;
}

.grid > .col-7-offset-6 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 50%;
}

.grid > .col-7-offset-7 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 58.3333333333%;
}

.grid > .col-7-offset-8 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 66.6666666667%;
}

.grid > .col-7-offset-9 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 75%;
}

.grid > .col-7-offset-10 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 83.3333333333%;
}

.grid > .col-7-offset-11 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 91.6666666667%;
}

.grid > .col-7-offset-12 {
  flex-basis: 58.3333333333%;
  max-width: 58.3333333333%;
  margin-left: 100%;
}

.grid > .col-8 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
}

.grid > .col-8-offset-1 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 8.3333333333%;
}

.grid > .col-8-offset-2 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 16.6666666667%;
}

.grid > .col-8-offset-3 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 25%;
}

.grid > .col-8-offset-4 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 33.3333333333%;
}

.grid > .col-8-offset-5 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 41.6666666667%;
}

.grid > .col-8-offset-6 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 50%;
}

.grid > .col-8-offset-7 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 58.3333333333%;
}

.grid > .col-8-offset-8 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 66.6666666667%;
}

.grid > .col-8-offset-9 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 75%;
}

.grid > .col-8-offset-10 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 83.3333333333%;
}

.grid > .col-8-offset-11 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 91.6666666667%;
}

.grid > .col-8-offset-12 {
  flex-basis: 66.6666666667%;
  max-width: 66.6666666667%;
  margin-left: 100%;
}

.grid > .col-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.grid > .col-9-offset-1 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 8.3333333333%;
}

.grid > .col-9-offset-2 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 16.6666666667%;
}

.grid > .col-9-offset-3 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 25%;
}

.grid > .col-9-offset-4 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 33.3333333333%;
}

.grid > .col-9-offset-5 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 41.6666666667%;
}

.grid > .col-9-offset-6 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 50%;
}

.grid > .col-9-offset-7 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 58.3333333333%;
}

.grid > .col-9-offset-8 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 66.6666666667%;
}

.grid > .col-9-offset-9 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 75%;
}

.grid > .col-9-offset-10 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 83.3333333333%;
}

.grid > .col-9-offset-11 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 91.6666666667%;
}

.grid > .col-9-offset-12 {
  flex-basis: 75%;
  max-width: 75%;
  margin-left: 100%;
}

.grid > .col-10 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
}

.grid > .col-10-offset-1 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 8.3333333333%;
}

.grid > .col-10-offset-2 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 16.6666666667%;
}

.grid > .col-10-offset-3 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 25%;
}

.grid > .col-10-offset-4 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 33.3333333333%;
}

.grid > .col-10-offset-5 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 41.6666666667%;
}

.grid > .col-10-offset-6 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 50%;
}

.grid > .col-10-offset-7 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 58.3333333333%;
}

.grid > .col-10-offset-8 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 66.6666666667%;
}

.grid > .col-10-offset-9 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 75%;
}

.grid > .col-10-offset-10 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 83.3333333333%;
}

.grid > .col-10-offset-11 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 91.6666666667%;
}

.grid > .col-10-offset-12 {
  flex-basis: 83.3333333333%;
  max-width: 83.3333333333%;
  margin-left: 100%;
}

.grid > .col-11 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
}

.grid > .col-11-offset-1 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 8.3333333333%;
}

.grid > .col-11-offset-2 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 16.6666666667%;
}

.grid > .col-11-offset-3 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 25%;
}

.grid > .col-11-offset-4 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 33.3333333333%;
}

.grid > .col-11-offset-5 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 41.6666666667%;
}

.grid > .col-11-offset-6 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 50%;
}

.grid > .col-11-offset-7 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 58.3333333333%;
}

.grid > .col-11-offset-8 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 66.6666666667%;
}

.grid > .col-11-offset-9 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 75%;
}

.grid > .col-11-offset-10 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 83.3333333333%;
}

.grid > .col-11-offset-11 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 91.6666666667%;
}

.grid > .col-11-offset-12 {
  flex-basis: 91.6666666667%;
  max-width: 91.6666666667%;
  margin-left: 100%;
}

.grid > .col-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.grid > .col-12-offset-1 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 8.3333333333%;
}

.grid > .col-12-offset-2 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 16.6666666667%;
}

.grid > .col-12-offset-3 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 25%;
}

.grid > .col-12-offset-4 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 33.3333333333%;
}

.grid > .col-12-offset-5 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 41.6666666667%;
}

.grid > .col-12-offset-6 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 50%;
}

.grid > .col-12-offset-7 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 58.3333333333%;
}

.grid > .col-12-offset-8 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 66.6666666667%;
}

.grid > .col-12-offset-9 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 75%;
}

.grid > .col-12-offset-10 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 83.3333333333%;
}

.grid > .col-12-offset-11 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 91.6666666667%;
}

.grid > .col-12-offset-12 {
  flex-basis: 100%;
  max-width: 100%;
  margin-left: 100%;
}

@media all and (max-width: 768px) {
  .page-wrapper {
    min-height: calc(100vh - 424px);
  }
  .grid-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
  .grid {
    margin-left: -20px;
    margin-right: -20px;
  }
  .card-wrapper {
    margin-top: -40px;
    margin-bottom: 40px;
  }
  .card-wrapper .grid > [class*='col']:not(.main-card) {
    flex-basis: 0;
    max-width: 0;
  }
  .card-wrapper .grid > [class*='col'].main-card {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media all and (max-width: 480px) {
  .page-wrapper {
    min-height: calc(100vh - 492px);
  }
  .grid-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
  .grid {
    margin-left: -16px;
    margin-right: -16px;
  }
  .card-wrapper {
    margin-top: -30px;
    margin-bottom: 30px;
  }
}

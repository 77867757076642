$rusty-red: #a31d11;
$tomato: #d22a1c;
$rose: #cd5d67;
$charcoal-grey: #3c3d3e;
$dried-blood: #4b0903;
$dark-grey-blue: #273951;
$white-two: #f2f2f2;
$slate-grey: #5c5f62;
$beige: #e6cbc5;

$bg-color: #f4f4f4;
//$font-color:
//$theme-color:

$font-family-primary: 'PingFangTC', '微軟正黑體', 'Microsoft JhengHei', sans-serif;
$font-family-OpenSans: 'Open Sans', $font-family-primary;
$font-family-SpectralSC: 'Spectral SC', serif;

$desktop: 1280px;
$tablet: 768px;
$mobile: 480px;

$grid-width: 1232px;

$font-size: 16px;

$normal-line-height: 1.375;

body {
    background-color: $bg-color; }

.page-wrapper {
    min-height: calc(100vh - 293px);

    &:after {
        content: '';
        display: flex; } }

.full-width-wrapper {
    //width: 100vw
    //margin-left: calc((100vw - 100%) / -2)
    width: 100%; }

.grid-wrapper {
    position: relative;
    margin: 0 auto;
    padding: 0 1rem;
    max-width: $grid-width; }

.card-wrapper {
    margin-top: -56px;
    margin-bottom: 56px; }

.grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;

    > [class*='col'] {
        flex: 1 1;
        padding: 0 1rem; }

    @for $i from 1 through 12 {
        > .col-#{$i} {
            flex-basis: percentage($i / 12);
            max-width: percentage($i / 12);

            @for $j from 1 through 12 {
                &-offset-#{$j} {
                    flex-basis: percentage($i / 12);
                    max-width: percentage($i / 12);
                    margin-left: percentage($j / 12); } } } } }

@media all and (max-width: $tablet) {
    .page-wrapper {
        min-height: calc(100vh - 424px); }

    .grid-wrapper {
        padding-left: 20px;
        padding-right: 20px; }

    .grid {
        margin-left: -20px;
        margin-right: -20px; }

    .card-wrapper {
        margin-top: -40px;
        margin-bottom: 40px;

        .grid > [class*='col']:not(.main-card) {
            flex-basis: 0;
            max-width: 0; }

        .grid > [class*='col'].main-card {
            flex-basis: 100%;
            max-width: 100%; } } }

@media all and (max-width: $mobile) {
    .page-wrapper {
        min-height: calc(100vh - 492px); }

    .grid-wrapper {
        padding-left: 16px;
        padding-right: 16px; }

    .grid {
        margin-left: -16px;
        margin-right: -16px; }

    .card-wrapper {
        margin-top: -30px;
        margin-bottom: 30px; } }

